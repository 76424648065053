import cx from 'classnames';
import FAQItems from 'components/FAQItems';
import FeatureCard from 'components/FeatureCard';
import ScreenshotDescription from 'components/ScreenshotDescription';
import Testimonials from 'components/Testimonials';
import {useRegisterEarlyAccess} from 'components/useRegisterEarlyAccess';
import {NextPage} from 'next';
import {NextSeo} from 'next-seo';
import Image from 'next/image';
import ActionableInput from './layouts/ActionableInput';
import Claim from './layouts/Claim';
import Section from './layouts/Section';
import GitHubStar from 'components/GitHubStar';
import {Parca, StripesStraight} from '@polarsignals/icons';

const Index: NextPage = () => {
  const {sent, error, isLoading, register} = useRegisterEarlyAccess();

  return (
    <>
      <NextSeo
        title="Polar Signals"
        description="Polar Signals is a continuous profiling project for applications and infrastructure."
      />

      <Claim
        title={
          <>
            Continuous Profiling
            <br />
            for Every Codebase
          </>
        }
        subTitle="Polar Signals is a continuous profiling project for applications and infrastructure. It helps you save money, improve performance and understand incidents better."
        bgIcon="faded"
      >
        <ActionableInput
          className={cx(sent && 'invisible')}
          inputName="email"
          placeholder="Enter your email"
          cta="Get Early Access"
          disabled={isLoading}
          hasError={!!error}
          onSubmit={register}
        />
        {sent && (
          <div className="p-0 absolute bottom-16" style={{color: '#A7EAD5'}}>
            ✅ Sent. We&apos;ll be in touch with you shortly!
          </div>
        )}
        {error && (
          <div className="p-0 absolute bottom-16" style={{color: '#ff8278'}}>
            <strong>Error:</strong> {error}
          </div>
        )}
      </Claim>

      <Section>
        <div className="grid grid-cols-12">
          <div className="col-start-auto col-span-12 xl:col-start-2 xl:col-span-10">
            <h2 id="why" className="mt-0">
              Why?
            </h2>

            <ScreenshotDescription
              title="Save Money"
              description="Many organizations have 20-30% of resources wasted with easily optimized code paths. The Polar Signals Agent aims to lower the entry bar by requiring 0 instrumentation for the whole infrastructure. Deploy in your infrastructure and get started!"
              imagePath="/screenshots/save-money.png"
              bgColor="#8CD5EF"
            />

            <ScreenshotDescription
              title="Improve Performance"
              description="Using profiling data collected over time, Polar Signals can with confidence and statistical significance determine hot paths to optimize. Additionally, it can show differences between any label dimension, such as deploys, versions, and regions."
              imagePath="/screenshots/improve-performance.png"
              bgColor="#4F6BEE"
            />

            <ScreenshotDescription
              title="Understand Incidents"
              description="Profiling data provides unique insight and depth into what a process executed over time. Memory leaks, but also momentary spikes in CPU or I/O causing unexpected behavior, is traditionally difficult to troubleshoot are a breeze with continuous profiling."
              imagePath="/screenshots/understand-incidents.png"
              bgColor="#EF887D"
            />
          </div>
        </div>
      </Section>

      <Section bgColor="#FFF6F2" bgColorEnd="#60D5F0">
        {/* offset 1 col from left and right */}
        <div className="grid grid-cols-12">
          <div className="col-start-auto col-span-12 xl:col-start-2 xl:col-span-10">
            {/* actual 3 col layout */}
            <div className="grid grid-cols-3 my-16">
              <div className="overflow-hidden flex justify-center hidden md:block">
                <StripesStraight className="transform -translate-y-14" />
              </div>
              <div className="col-span-3 md:col-span-2">
                <h2 className="mt-0">What is Polar Signals?</h2>
                <p className="max-w-prose">
                  Polar Signals is a continuous profiling product for applications and
                  infrastructure. It helps you save money, improve performance and understand
                  incidents better.
                  <br />
                  <br />
                  Continuous profiling is the act of taking profiles (such as CPU, Memory, I/O and
                  more) of programs in a systematic way. Parca collects, stores and makes profiles
                  available to be queried over time. It features a powerful multi-dimensional data
                  model, storage and query engine specifically designed for profiling data. Find out
                  more in the Overview.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <FeatureCard
                imagePath="/features/ebpf.png"
                title="eBPF Profiler"
                text="A single profiler, using eBPF, automatically discovering targets from Kubernetes or SystemD across the entire infrastructure with very low overhead. Supports C, C++, Rust, Go, and more!"
              />
              <FeatureCard
                imagePath="/features/db.png"
                title="Optimized Storage & Querying"
                text="Efficiently storing profiling data while retaining raw data and allowing slicing and dicing of data through a label-based search. Aggregate profiling data infrastructure wide, view single profiles in time or compare on any dimension."
              />
              <FeatureCard
                imagePath="/features/book.png"
                title="Open Standards"
                text="Supports any pprof formatted profiles allowing for wide language adoption and interoperability with existing tooling."
              />
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div id="open-source" className="">
          <h2 className="mt-0 mb-4 text-center">OPEN SOURCE</h2>
          <div className="mb-4 text-center">
            <p>
              <span>
                Open Source is part of our DNA. Polar Signals originally created the{' '}
                <a
                  href="https://github.com/parca-dev/parca"
                  target="_blank"
                  rel="noreferrer"
                  style={{color: '#7b8ab9'}}
                >
                  Parca
                </a>{' '}
                project, and maintains it.
              </span>
            </p>
          </div>
          <div className="mb-4 text-center">
            <a
              href="https://github.com/parca-dev/parca"
              target="_blank"
              rel="noreferrer"
              className="mb-4"
              style={{display: 'flex', alignItems: 'center'}}
            >
              <Parca
                height={80}
                style={{display: 'block', margin: '0 auto', textAlign: 'center'}}
              />
            </a>
            <GitHubStar owner="parca-dev" repo="parca" />
          </div>
          <div className="text-center">
            <a
              href="https://github.com/parca-dev/parca"
              target="_blank"
              rel="noreferrer"
              style={{color: 'white', backgroundColor: '#5263F1'}}
              className="inline-flex items-center h-12 px-6 m-2 text-lg transition-colors duration-150 rounded-lg"
            >
              Learn More
            </a>
          </div>
        </div>
      </Section>

      <Section bgColor="#F5F5F5">
        <h2 className="mt-0 text-center" id="faq">
          FAQ
        </h2>
        <FAQItems
          items={[
            {
              question: 'What languages are currently supported?',
              answer:
                'Our agent supports all compiled languages, eg. C, C++, Rust, Go (with extended support for Go). With native libraries, any pprof formatted profile can be written to Polar Signals. Further language support coming in the upcoming weeks and months.',
            },
            {
              question: 'What overhead does running always-on profiling have?',
              answer:
                'We have observed <1% in CPU, but more elaborate and reproducible reports coming.',
            },
            {
              question: 'Does this mean Polar Signals has our binaries and our code?',
              answer:
                'No. Profiling data is made up of statistics representing for example how much time the CPU has spent in a particular function, but the function metadata is decoupled from the actual executable code. Think of it as statistics and metadata for us humans to make sense of the statistics.',
            },
          ]}
        />
      </Section>

      <Section>
        <div className="">
          <div className="uppercase mb-4">With Experience From</div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-x-12 gap-y-6 items-center">
            <div>
              <Image
                loading="lazy"
                src="/logos/core-os.png"
                alt="core OS"
                width={207}
                height={80}
              />
            </div>
            <div>
              <Image
                loading="lazy"
                src="/logos/hashicorp.png"
                alt="HashiCorp"
                width={222}
                height={50}
              />
            </div>
            <div>
              <Image
                loading="lazy"
                src="/logos/digital-ocean.png"
                alt="Digital Ocean"
                width={248}
                height={42}
              />
            </div>
            <div>
              <Image
                loading="lazy"
                src="/logos/red-hat.png"
                alt="Red Hat"
                width={248}
                height={64}
              />
            </div>
          </div>
        </div>
        <div className="mt-16">
          <div className="uppercase mb-4">Maintaining Projects</div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-x-12 gap-y-6 items-center">
            <div>
              <Image
                loading="lazy"
                src="/logos/prometheus.png"
                alt="Prometheus"
                width={219}
                height={62}
              />
            </div>
            <div>
              <Image loading="lazy" src="/logos/thanos.png" alt="Thanos" width={185} height={46} />
            </div>
            <div>
              <Image loading="lazy" src="/logos/cortex.png" alt="cortex" width={168} height={63} />
            </div>
          </div>
        </div>
      </Section>

      <Section bgColor="#4F6BEE">
        <Testimonials
          items={[
            {
              name: 'Sandor Szücs',
              role: 'Platform Engineer at Zalando',
              text: '"Polar Signals allows us to troubleshoot and identify memory and CPU issues faster than ever before!"',
              imagePath: '/testimonials/sandor-szucs.png',
            },
            {
              name: 'Rob Skillington',
              role: 'CEO of Chronosphere',
              text: '"Merging samples is one of the most powerful workflows continuous profiling enables!"',
              imagePath: '/testimonials/rob-skillington.png',
            },
            {
              name: 'Jimmy Zelinskie',
              role: 'Founder Authzed',
              text: '"I\'ve written high-performance Go and there is not much of a trick beyond just profiling prod systems"',
              imagePath: '/testimonials/jimmy-zelinskie.png',
            },
          ]}
        ></Testimonials>
      </Section>
    </>
  );
};

export default Index;
