import styled from '@emotion/styled';
import {useState} from 'react';
import cx from 'classnames';

const Form = styled.form`
  position: relative;
  display: inline-flex;

  @media (max-width: 640px) {
    width: 100%;
  }
`;

const Input = styled.input`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  background: none;
  border: 1px solid white;
  border-radius: 12px;
  width: 500px;

  @media (max-width: 640px) {
    width: 100%;
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  &[disabled] {
    opacity: 0.5;
  }

  &.error {
    border-color: #ff8278 !important;
  }
`;

const Submit = styled.button`
  position: absolute;
  right: 0;
  text-transform: uppercase;
  padding: 10px 30px;
  background-color: white;
  border-radius: 12px;
  height: 100%;
  color: #4c68f6;
  font-size: 14px;
`;

const ActionableInput = ({
  placeholder,
  cta,
  formId = null,
  inputId = null,
  inputName = null,
  formAction = null,
  formActionMethod = null,
  formActionTarget = null,
  onSubmit,
  className,
  disabled,
  hasError,
}: any) => {
  const [value, setValue] = useState('');

  return (
    <>
      <Form
        id={formId}
        action={formAction}
        method={formActionMethod}
        target={formActionTarget}
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={evt => {
          if (onSubmit) {
            evt.preventDefault();
            onSubmit(value);
          }
        }}
        className={className}
      >
        <Input
          id={inputId}
          name={inputName}
          className={cx('outline-none pr-8 sm:pr-52', hasError && 'error')}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={evt => setValue(evt.target.value)}
        />
        <Submit type="submit" className="hidden sm:block top-0">
          {cta}
        </Submit>
        <Submit type="submit" className="block sm:hidden block top-16 w-full">
          {cta}
        </Submit>
      </Form>
      <div className="block sm:hidden mb-32"></div>
    </>
  );
};

export default ActionableInput;
