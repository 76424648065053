import styled from '@emotion/styled';
import {StripesAngular, PolarSignalsFaded} from '@polarsignals/icons';

const ClaimContainer = styled.div`
  position: relative;
  padding-top: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin-bottom: 32px;
  }

  a {
    color: white !important;
  }
`;

const CustomContainer = styled.div`
  margin-top: 64px;
  shape-outside: circle(50%);
`;

const BackgroundIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: -7%;
`;

export const GradientContainer = ({
  style,
  children,
  className,
  gradient = {
    topLeft: '#4c67f6',
    topRight: '#82cfe5',
    bottomLeft: '#5263f1',
    bottomRight: '#fe8b60',
    center: '#957ec6',
  },
}: {
  style?: object;
  children?: any;
  className?: string;
  gradient?: {
    topLeft: string;
    topRight: string;
    bottomLeft: string;
    bottomRight: string;
    center: string;
  };
}) => {
  const gradientStyle = {
    background: `linear-gradient(
      to top left,
      ${gradient.bottomRight},
      rgba(255, 153, 150, 0),
      ${gradient.topLeft}
    ),
    linear-gradient(to top right, ${gradient.bottomLeft}, rgba(255, 153, 150, 0), ${gradient.topRight})
      ${gradient.center}`,
    ...style,
  };
  return (
    <ClaimContainer style={gradientStyle} className={className}>
      {children}
    </ClaimContainer>
  );
};

const Claim = ({
  title,
  subTitle,
  bgIcon = 'faded',
  children,
  gradient = {
    topLeft: '#4c67f6',
    topRight: '#82cfe5',
    bottomLeft: '#5263f1',
    bottomRight: '#fe8b60',
    center: '#957ec6',
  },
}: {
  title: any;
  subTitle?: any;
  bgIcon: 'faded' | 'stripes' | 'none';
  children?: any;
  gradient?: {
    topLeft: string;
    topRight: string;
    bottomLeft: string;
    bottomRight: string;
    center: string;
  };
}) => {
  const styles = {
    minHeight: subTitle || children ? '80vh' : '320px',
  };
  return (
    <GradientContainer style={styles} gradient={gradient}>
      <div className="container mx-auto px-4 text-white">
        <h1 className="leading-tight" id="get-access">
          {title}
        </h1>

        {(subTitle || children) && (
          <>
            <div className="text-lg whitespace-pre-line mb-4 max-w-prose">{subTitle}</div>
            {children && <CustomContainer>{children}</CustomContainer>}
          </>
        )}
      </div>
      <BackgroundIcon className="hidden lg:block">
        {bgIcon === 'faded' && <PolarSignalsFaded width={600} height="auto" />}
        {bgIcon === 'stripes' && <StripesAngular width={600} />}
      </BackgroundIcon>
    </GradientContainer>
  );
};

export default Claim;
