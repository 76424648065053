import {useCallback, useState} from 'react';

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const useRegisterEarlyAccess = () => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const register = useCallback(async (email: string) => {
    setError('');

    if (!validateEmail(email)) {
      setError('invalid email address');
      return;
    }

    setIsLoading(true);

    return await fetch('https://hooks.zapier.com/hooks/catch/11004478/btxvfmt', {
      method: 'POST',
      body: email,
    })
      .then(() => setSent(true))
      .catch(error => setError(error))
      .finally(() => setIsLoading(false));
  }, []);

  return {sent, error, isLoading, register};
};
