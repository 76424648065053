import React, {useEffect, useState} from 'react';
import {GitHub} from '@polarsignals/icons';

export interface IPropTypes {
  owner: string;
  repo: string;
  color?: string;
}

const GitHubStar: React.FC<IPropTypes> = props => {
  const {owner, repo, color = '#6a737d'} = props;
  const [stargazersCount, setStargazersCount] = useState(0);

  useEffect(() => {
    const getCount = async () => {
      const res = await fetch(`https://api.github.com/repos/${owner}/${repo}`);
      const resJson = await res.json();
      setStargazersCount(resJson.stargazers_count);
    };
    getCount().catch(err => console.log('Error getting GitHub star count', err));
  }, [owner, repo]);

  return (
    <div className="inline-flex">
      <a
        title={`Star ${owner}/${repo}`}
        className="rounded-l"
        style={{
          backgroundColor: '#FAFBFC',
          fontSize: '12px',
          lineHeight: '20px',
          padding: '3px 10px',
          border: '1px solid rgba(27, 31, 35, 0.2)',
          float: 'left',
        }}
        href={`https://github.com/${owner}/${repo}`}
        target="_blank"
        rel="noreferrer"
      >
        <span style={{color}}>
          <GitHub
            width={16}
            height={16}
            style={{
              display: 'inline-block',
              userSelect: 'none',
              verticalAlign: 'text-bottom',
            }}
          />
        </span>
        {' Star'}
      </a>
      <a
        href={`https://github.com/${owner}/${repo}/stargazers`}
        target="_blank"
        rel="noreferrer"
        className="rounded-r"
        style={{
          border: '1px solid rgba(27, 31, 35, 0.2)',
          borderLeft: 0,
          float: 'left',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '20px',
          padding: '3px 10px',
          verticalAlign: 'middle',
          textDecoration: 'none',
        }}
      >
        {stargazersCount}
      </a>
    </div>
  );
};

export default React.memo(GitHubStar);
