import styled from '@emotion/styled';
import Image from 'next/image';

const Card = styled.div`
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
`;

const Title = styled.div`
  font-size: 32px;
  margin: 32px 0;
  line-height: 100%;
`;

const FeatureCard = ({
  imagePath,
  title,
  text,
}: {
  imagePath: string;
  title: string;
  text: string;
}) => {
  return (
    <Card className="w-full py-12 px-12 lg:px-6 xl:px-12 text-center">
      <div>
        <div className="">
          <Image loading="lazy" src={imagePath} alt={title} width={82} height={86} />
        </div>
        <Title>{title}</Title>
        <p>{text}</p>
      </div>
    </Card>
  );
};

export default FeatureCard;
