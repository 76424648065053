const Section = ({
  bgColor,
  bgColorEnd,
  children,
}: {
  bgColor?: string;
  bgColorEnd?: string;
  children?: any;
}) => {
  const colorEnd = !bgColorEnd ? bgColor : bgColorEnd;
  const linearGradient =
    bgColor && colorEnd
      ? `linear-gradient(295.7deg, ${colorEnd} -14.77%, ${bgColor} 95.22%)`
      : null;
  return (
    <section className="" style={linearGradient ? {background: linearGradient} : undefined}>
      <div className="container mx-auto px-4 py-24">{children}</div>
    </section>
  );
};

export default Section;
